import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/home/hero"
import Education from "../components/home/education"
import About from "../components/home/about"
import RecentNews from "../components/home/recent_news"
import RecentNewsFR from "../components/home/recent_news_fr"
import OurWork from "../components/home/our_work"
import Member from "../components/home/member"
import BookCommb from "../components/home/book"

import gsap from "gsap"
import "../../static/assets/css/anim.css"

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <Seo title="Home" description="COMMB is the national not-for-profit organization for the Canadian out-of-home (OOH) industry. Our membership base is comprised of advertisers, agencies, programmatic tech-stacks and OOH companies, large and small." />
      <div className="main_content_wrapper">
        <Hero data={data.contentfulHome} />
        <Education data={data.contentfulHome} />
        <About data={data.contentfulHome} />
        <RecentNews />
        <RecentNewsFR />
        <OurWork data={data.contentfulHome} />
        <Member data={data.contentfulHome} />
        <BookCommb data={data.contentfulHome} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Home($language: String) {
    contentfulHome(node_locale: { eq: $language }) {
      banner {
        title
        headlineL2
        titleOptional
        description {
          description
        }
        label
        url
        images {
          id
          file {
            url
          }
        }
      }

      education {
        id
        url
        image {
          file {
            url
          }
        }
        updatedAt
      }

      section3Title1
      section3Title2
      section3Description {
        section3Description
      }
      section3ImageBackground {
        gatsbyImageData
      }
      section3ImageFront {
        gatsbyImageData
      }
      section3ImageShapes {
        file {
          url
        }
      }
      section3Button {
        label
        url
      }

      section6Title
      section6Projects {
        id
        title
        description {
          raw
        }
        image {
          gatsbyImageData
        }
      }

      section7Title
      section7Description {
        section7Description
      }
      section7Label
      section7Url

      section9Title
      section9ShortText
      bookCommb {
        id
        description {
          description
        }
        label
        url
      }
      section9Image1 {
        gatsbyImageData
      }
      section9Image3 {
        file {
          url
        }
      }
    }
  }
`